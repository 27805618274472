import { cloneDeep } from 'lodash';

import {
  DEFAULT_RAPID_REFRESH_BACKGROUND_IMAGE,
  DEFAULT_RAPID_REFRESH_LOGO
} from '@maggie/components/rapid-refresh/default-background-image';
import { ENV } from '@maggie/config/env';
import { Platform } from '@maggie/cordova/platform';
import type { ConfigurationType } from '@maggie/store/courseware/lessons/types';
import { RapidRefreshActions } from '@maggie/store/courseware/rapid-refreshes/actions';
import { UserSelectors } from '@maggie/store/user/selectors';

import { RapidRefreshAttempt } from '../attempt/rapid-refresh-attempt';
import { ThomasPlayer } from './thomas-player';
import type { ConfigurationForThomas, ContextForThomas } from './thomas-player-interface';

export class RapidRefreshThomasPlayer extends ThomasPlayer {
  private attempt: RapidRefreshAttempt;

  constructor(
    id: string,
    session: number,
    configuration: ConfigurationType,
    thomasVersion: string
  ) {
    super(id, configuration, thomasVersion);
    this.attempt = new RapidRefreshAttempt(id, session, configuration.slides);
  }

  /**
   * Returns the lesson configuration object with some extra properties for thomas
   */
  private getLessonConfigurationForThomas = (config: ConfigurationType): ConfigurationForThomas => {
    const configuration = cloneDeep(config);

    // disable social learning in case backend forgot!?
    for (const slide of configuration.slides) {
      if (slide?.data?.socialLearning?.enabled) {
        slide.data.socialLearning.enabled = false;
      }
    }

    return {
      ...configuration,
      config: {
        ...configuration.styleConfiguration,
        hideNavMenuBtn: true,
        isQuiz: true
      },
      styleConfiguration: {
        ...configuration.styleConfiguration,
        indicator: true,
        pageNumbers: true,
        hasAnswerFeedback: true,
        enableCustomCSS: true,
        customCSS: '',
        logo: configuration.styleConfiguration?.logo || DEFAULT_RAPID_REFRESH_LOGO,
        background:
          configuration.styleConfiguration?.background || DEFAULT_RAPID_REFRESH_BACKGROUND_IMAGE,
        colors: configuration.styleConfiguration.colors || {
          text: 'white',
          background: 'black'
        }
      }
    };
  };

  protected getContextForThomas = (): ContextForThomas => {
    const configuration = this.thomasAssets.getConfiguration();
    return {
      slidedeck: {
        id: this.id,
        userId: UserSelectors.getId(window.__store.getState()),
        stars: {},
        enableStars: false,
        alreadyCompleted: false,
        disableAnswerFeedback: false,
        isScored: true,
        platform: Platform.get(),
        config: this.getLessonConfigurationForThomas(configuration),
        aiccURL: ENV.apiUrl('/aicc')
      },
      uploadConfiguration: {}
    };
  };

  /**
   * @throws error if it does not successfully open
   */
  public open = async () => {
    await super.open();

    this.attempt.startAttempt();
    this.attempt.startRecording(this.id);
  };

  public close = async () => {
    await super.close();

    this.attempt.finishAttempt();
    this.attempt.stopRecording(this.id);

    window.__store.dispatch(RapidRefreshActions.setRapidRefreshDialogOpen(true));
  };
}
